
var ortWasm = (() => {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  if (typeof __filename !== 'undefined') _scriptDir = _scriptDir || __filename;
  return (
function(ortWasm) {
  ortWasm = ortWasm || {};


var c;c||(c=typeof ortWasm !== 'undefined' ? ortWasm : {});var aa=Object.assign,ba,g;c.ready=new Promise(function(a,b){ba=a;g=b});var ca=aa({},c),r="./this.program",da="object"===typeof window,t="function"===typeof importScripts,ea="object"===typeof process&&"object"===typeof process.versions&&"string"===typeof process.versions.node,w="",x,y,A,fs,B,C;
if(ea)w=t?require("path").dirname(w)+"/":__dirname+"/",C=()=>{B||(fs=require("fs"),B=require("path"))},x=function(a,b){C();a=B.normalize(a);return fs.readFileSync(a,b?null:"utf8")},A=a=>{a=x(a,!0);a.buffer||(a=new Uint8Array(a));return a},y=(a,b,e)=>{C();a=B.normalize(a);fs.readFile(a,function(f,h){f?e(f):b(h.buffer)})},1<process.argv.length&&(r=process.argv[1].replace(/\\/g,"/")),process.argv.slice(2),process.on("uncaughtException",function(a){throw a;}),process.on("unhandledRejection",function(a){throw a;
}),c.inspect=function(){return"[Emscripten Module object]"};else if(da||t)t?w=self.location.href:"undefined"!==typeof document&&document.currentScript&&(w=document.currentScript.src),_scriptDir&&(w=_scriptDir),0!==w.indexOf("blob:")?w=w.substr(0,w.replace(/[?#].*/,"").lastIndexOf("/")+1):w="",x=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.send(null);return b.responseText},t&&(A=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.responseType="arraybuffer";b.send(null);return new Uint8Array(b.response)}),
y=(a,b,e)=>{var f=new XMLHttpRequest;f.open("GET",a,!0);f.responseType="arraybuffer";f.onload=()=>{200==f.status||0==f.status&&f.response?b(f.response):e()};f.onerror=e;f.send(null)};var fa=c.print||console.log.bind(console),D=c.printErr||console.warn.bind(console);aa(c,ca);ca=null;c.thisProgram&&(r=c.thisProgram);var E;c.wasmBinary&&(E=c.wasmBinary);var noExitRuntime=c.noExitRuntime||!1;"object"!==typeof WebAssembly&&F("no native wasm support detected");
var G,ha=!1,ia="undefined"!==typeof TextDecoder?new TextDecoder("utf8"):void 0;
function ja(a,b,e){var f=b+e;for(e=b;a[e]&&!(e>=f);)++e;if(16<e-b&&a.subarray&&ia)return ia.decode(a.subarray(b,e));for(f="";b<e;){var h=a[b++];if(h&128){var k=a[b++]&63;if(192==(h&224))f+=String.fromCharCode((h&31)<<6|k);else{var l=a[b++]&63;h=224==(h&240)?(h&15)<<12|k<<6|l:(h&7)<<18|k<<12|l<<6|a[b++]&63;65536>h?f+=String.fromCharCode(h):(h-=65536,f+=String.fromCharCode(55296|h>>10,56320|h&1023))}}else f+=String.fromCharCode(h)}return f}function H(a,b){return a?ja(I,a,b):""}
function J(a,b,e,f){if(!(0<f))return 0;var h=e;f=e+f-1;for(var k=0;k<a.length;++k){var l=a.charCodeAt(k);if(55296<=l&&57343>=l){var p=a.charCodeAt(++k);l=65536+((l&1023)<<10)|p&1023}if(127>=l){if(e>=f)break;b[e++]=l}else{if(2047>=l){if(e+1>=f)break;b[e++]=192|l>>6}else{if(65535>=l){if(e+2>=f)break;b[e++]=224|l>>12}else{if(e+3>=f)break;b[e++]=240|l>>18;b[e++]=128|l>>12&63}b[e++]=128|l>>6&63}b[e++]=128|l&63}}b[e]=0;return e-h}
function K(a){for(var b=0,e=0;e<a.length;++e){var f=a.charCodeAt(e);55296<=f&&57343>=f&&(f=65536+((f&1023)<<10)|a.charCodeAt(++e)&1023);127>=f?++b:b=2047>=f?b+2:65535>=f?b+3:b+4}return b}function ka(a){var b=K(a)+1,e=L(b);e&&J(a,M,e,b);return e}var la,M,I,N;
function ma(){var a=G.buffer;la=a;c.HEAP8=M=new Int8Array(a);c.HEAP16=new Int16Array(a);c.HEAP32=N=new Int32Array(a);c.HEAPU8=I=new Uint8Array(a);c.HEAPU16=new Uint16Array(a);c.HEAPU32=new Uint32Array(a);c.HEAPF32=new Float32Array(a);c.HEAPF64=new Float64Array(a)}var na,oa=[],pa=[],qa=[];function ra(){var a=c.preRun.shift();oa.unshift(a)}var O=0,P=null,Q=null;c.preloadedImages={};c.preloadedAudios={};
function F(a){if(c.onAbort)c.onAbort(a);a="Aborted("+a+")";D(a);ha=!0;a=new WebAssembly.RuntimeError(a+". Build with -s ASSERTIONS=1 for more info.");g(a);throw a;}function sa(){return R.startsWith("data:application/octet-stream;base64,")}var R;R="ort-wasm.wasm";if(!sa()){var ta=R;R=c.locateFile?c.locateFile(ta,w):w+ta}function ua(){var a=R;try{if(a==R&&E)return new Uint8Array(E);if(A)return A(a);throw"both async and sync fetching of the wasm failed";}catch(b){F(b)}}
function va(){if(!E&&(da||t)){if("function"===typeof fetch&&!R.startsWith("file://"))return fetch(R,{credentials:"same-origin"}).then(function(a){if(!a.ok)throw"failed to load wasm binary file at '"+R+"'";return a.arrayBuffer()}).catch(function(){return ua()});if(y)return new Promise(function(a,b){y(R,function(e){a(new Uint8Array(e))},b)})}return Promise.resolve().then(function(){return ua()})}
function S(a){for(;0<a.length;){var b=a.shift();if("function"==typeof b)b(c);else{var e=b.Na;"number"===typeof e?void 0===b.va?wa(e)():wa(e)(b.va):e(void 0===b.va?null:b.va)}}}var T=[];function wa(a){var b=T[a];b||(a>=T.length&&(T.length=a+1),T[a]=b=na.get(a));return b}
function xa(a){this.qa=a-16;this.Fa=function(b){N[this.qa+4>>2]=b};this.Ca=function(b){N[this.qa+8>>2]=b};this.Da=function(){N[this.qa>>2]=0};this.Ba=function(){M[this.qa+12>>0]=0};this.Ea=function(){M[this.qa+13>>0]=0};this.ya=function(b,e){this.Fa(b);this.Ca(e);this.Da();this.Ba();this.Ea()}}var ya=0,za={},Aa=[null,[],[]],U={};
function Ba(a,b,e){function f(v){return(v=v.toTimeString().match(/\(([A-Za-z ]+)\)$/))?v[1]:"GMT"}var h=(new Date).getFullYear(),k=new Date(h,0,1),l=new Date(h,6,1);h=k.getTimezoneOffset();var p=l.getTimezoneOffset();N[a>>2]=60*Math.max(h,p);N[b>>2]=Number(h!=p);a=f(k);b=f(l);a=ka(a);b=ka(b);p<h?(N[e>>2]=a,N[e+4>>2]=b):(N[e>>2]=b,N[e+4>>2]=a)}function Ca(a,b,e){Ca.xa||(Ca.xa=!0,Ba(a,b,e))}var Ga;Ga=ea?()=>{var a=process.hrtime();return 1E3*a[0]+a[1]/1E6}:()=>performance.now();var Ha={};
function Ia(){if(!Ja){var a={USER:"web_user",LOGNAME:"web_user",PATH:"/",PWD:"/",HOME:"/home/web_user",LANG:("object"===typeof navigator&&navigator.languages&&navigator.languages[0]||"C").replace("-","_")+".UTF-8",_:r||"./this.program"},b;for(b in Ha)void 0===Ha[b]?delete a[b]:a[b]=Ha[b];var e=[];for(b in a)e.push(b+"="+a[b]);Ja=e}return Ja}var Ja;function V(a){return 0===a%4&&(0!==a%100||0===a%400)}function Ka(a,b){for(var e=0,f=0;f<=b;e+=a[f++]);return e}
var W=[31,29,31,30,31,30,31,31,30,31,30,31],X=[31,28,31,30,31,30,31,31,30,31,30,31];function Y(a,b){for(a=new Date(a.getTime());0<b;){var e=a.getMonth(),f=(V(a.getFullYear())?W:X)[e];if(b>f-a.getDate())b-=f-a.getDate()+1,a.setDate(1),11>e?a.setMonth(e+1):(a.setMonth(0),a.setFullYear(a.getFullYear()+1));else{a.setDate(a.getDate()+b);break}}return a}
function La(a,b,e,f){function h(d,m,n){for(d="number"===typeof d?d.toString():d||"";d.length<m;)d=n[0]+d;return d}function k(d,m){return h(d,m,"0")}function l(d,m){function n(Da){return 0>Da?-1:0<Da?1:0}var z;0===(z=n(d.getFullYear()-m.getFullYear()))&&0===(z=n(d.getMonth()-m.getMonth()))&&(z=n(d.getDate()-m.getDate()));return z}function p(d){switch(d.getDay()){case 0:return new Date(d.getFullYear()-1,11,29);case 1:return d;case 2:return new Date(d.getFullYear(),0,3);case 3:return new Date(d.getFullYear(),
0,2);case 4:return new Date(d.getFullYear(),0,1);case 5:return new Date(d.getFullYear()-1,11,31);case 6:return new Date(d.getFullYear()-1,11,30)}}function v(d){d=Y(new Date(d.oa+1900,0,1),d.ua);var m=new Date(d.getFullYear()+1,0,4),n=p(new Date(d.getFullYear(),0,4));m=p(m);return 0>=l(n,d)?0>=l(m,d)?d.getFullYear()+1:d.getFullYear():d.getFullYear()-1}var u=N[f+40>>2];f={Ia:N[f>>2],Ha:N[f+4>>2],sa:N[f+8>>2],ra:N[f+12>>2],pa:N[f+16>>2],oa:N[f+20>>2],ta:N[f+24>>2],ua:N[f+28>>2],Qa:N[f+32>>2],Ga:N[f+
36>>2],Ja:u?H(u):""};e=H(e);u={"%c":"%a %b %d %H:%M:%S %Y","%D":"%m/%d/%y","%F":"%Y-%m-%d","%h":"%b","%r":"%I:%M:%S %p","%R":"%H:%M","%T":"%H:%M:%S","%x":"%m/%d/%y","%X":"%H:%M:%S","%Ec":"%c","%EC":"%C","%Ex":"%m/%d/%y","%EX":"%H:%M:%S","%Ey":"%y","%EY":"%Y","%Od":"%d","%Oe":"%e","%OH":"%H","%OI":"%I","%Om":"%m","%OM":"%M","%OS":"%S","%Ou":"%u","%OU":"%U","%OV":"%V","%Ow":"%w","%OW":"%W","%Oy":"%y"};for(var q in u)e=e.replace(new RegExp(q,"g"),u[q]);var Ea="Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),
Fa="January February March April May June July August September October November December".split(" ");u={"%a":function(d){return Ea[d.ta].substring(0,3)},"%A":function(d){return Ea[d.ta]},"%b":function(d){return Fa[d.pa].substring(0,3)},"%B":function(d){return Fa[d.pa]},"%C":function(d){return k((d.oa+1900)/100|0,2)},"%d":function(d){return k(d.ra,2)},"%e":function(d){return h(d.ra,2," ")},"%g":function(d){return v(d).toString().substring(2)},"%G":function(d){return v(d)},"%H":function(d){return k(d.sa,
2)},"%I":function(d){d=d.sa;0==d?d=12:12<d&&(d-=12);return k(d,2)},"%j":function(d){return k(d.ra+Ka(V(d.oa+1900)?W:X,d.pa-1),3)},"%m":function(d){return k(d.pa+1,2)},"%M":function(d){return k(d.Ha,2)},"%n":function(){return"\n"},"%p":function(d){return 0<=d.sa&&12>d.sa?"AM":"PM"},"%S":function(d){return k(d.Ia,2)},"%t":function(){return"\t"},"%u":function(d){return d.ta||7},"%U":function(d){var m=new Date(d.oa+1900,0,1),n=0===m.getDay()?m:Y(m,7-m.getDay());d=new Date(d.oa+1900,d.pa,d.ra);return 0>
l(n,d)?k(Math.ceil((31-n.getDate()+(Ka(V(d.getFullYear())?W:X,d.getMonth()-1)-31)+d.getDate())/7),2):0===l(n,m)?"01":"00"},"%V":function(d){var m=new Date(d.oa+1901,0,4),n=p(new Date(d.oa+1900,0,4));m=p(m);var z=Y(new Date(d.oa+1900,0,1),d.ua);return 0>l(z,n)?"53":0>=l(m,z)?"01":k(Math.ceil((n.getFullYear()<d.oa+1900?d.ua+32-n.getDate():d.ua+1-n.getDate())/7),2)},"%w":function(d){return d.ta},"%W":function(d){var m=new Date(d.oa,0,1),n=1===m.getDay()?m:Y(m,0===m.getDay()?1:7-m.getDay()+1);d=new Date(d.oa+
1900,d.pa,d.ra);return 0>l(n,d)?k(Math.ceil((31-n.getDate()+(Ka(V(d.getFullYear())?W:X,d.getMonth()-1)-31)+d.getDate())/7),2):0===l(n,m)?"01":"00"},"%y":function(d){return(d.oa+1900).toString().substring(2)},"%Y":function(d){return d.oa+1900},"%z":function(d){d=d.Ga;var m=0<=d;d=Math.abs(d)/60;return(m?"+":"-")+String("0000"+(d/60*100+d%60)).slice(-4)},"%Z":function(d){return d.Ja},"%%":function(){return"%"}};for(q in u)e.includes(q)&&(e=e.replace(new RegExp(q,"g"),u[q](f)));q=Ma(e);if(q.length>b)return 0;
M.set(q,a);return q.length-1}function Ma(a){var b=Array(K(a)+1);J(a,b,0,b.length);return b}
var Qa={a:function(a){return L(a+16)+16},b:function(a,b,e){(new xa(a)).ya(b,e);ya++;throw a;},g:function(){return 0},I:function(){},F:function(){},v:function(){},y:function(){},r:function(){return 0},G:function(){},B:function(a,b){a=H(a);return U.Ka(a,b)},A:function(a,b,e,f,h,k){k<<=12;if(0!==(f&16)&&0!==a%65536)b=-28;else if(0!==(f&32)){a=65536*Math.ceil(b/65536);var l=Na(65536,a);l?(I.fill(0,l,l+a),a=l):a=0;a?(za[a]={Aa:a,za:b,wa:!0,fd:h,Pa:e,flags:f,offset:k},b=a):b=-48}else b=-52;return b},z:function(a,
b){var e=za[a];0!==b&&e?(b===e.za&&(za[a]=null,e.wa&&Oa(e.Aa)),a=0):a=-28;return a},k:function(){},x:function(a,b,e){a=H(a);return U.La(a,b,e)},t:function(){},H:function(){},u:function(){},h:function(){F("To use dlopen, you need to use Emscripten's linking support, see https://github.com/emscripten-core/emscripten/wiki/Linking")},n:function(){F("To use dlopen, you need to use Emscripten's linking support, see https://github.com/emscripten-core/emscripten/wiki/Linking")},J:function(a,b){a=new Date(1E3*
N[a>>2]);N[b>>2]=a.getUTCSeconds();N[b+4>>2]=a.getUTCMinutes();N[b+8>>2]=a.getUTCHours();N[b+12>>2]=a.getUTCDate();N[b+16>>2]=a.getUTCMonth();N[b+20>>2]=a.getUTCFullYear()-1900;N[b+24>>2]=a.getUTCDay();N[b+28>>2]=(a.getTime()-Date.UTC(a.getUTCFullYear(),0,1,0,0,0,0))/864E5|0},K:function(a,b){a=new Date(1E3*N[a>>2]);N[b>>2]=a.getSeconds();N[b+4>>2]=a.getMinutes();N[b+8>>2]=a.getHours();N[b+12>>2]=a.getDate();N[b+16>>2]=a.getMonth();N[b+20>>2]=a.getFullYear()-1900;N[b+24>>2]=a.getDay();var e=new Date(a.getFullYear(),
0,1);N[b+28>>2]=(a.getTime()-e.getTime())/864E5|0;N[b+36>>2]=-(60*a.getTimezoneOffset());var f=(new Date(a.getFullYear(),6,1)).getTimezoneOffset();e=e.getTimezoneOffset();N[b+32>>2]=(f!=e&&a.getTimezoneOffset()==Math.min(e,f))|0},L:function(a){var b=new Date(N[a+20>>2]+1900,N[a+16>>2],N[a+12>>2],N[a+8>>2],N[a+4>>2],N[a>>2],0),e=N[a+32>>2],f=b.getTimezoneOffset(),h=new Date(b.getFullYear(),0,1),k=(new Date(b.getFullYear(),6,1)).getTimezoneOffset(),l=h.getTimezoneOffset(),p=Math.min(l,k);0>e?N[a+32>>
2]=Number(k!=l&&p==f):0<e!=(p==f)&&(k=Math.max(l,k),b.setTime(b.getTime()+6E4*((0<e?p:k)-f)));N[a+24>>2]=b.getDay();N[a+28>>2]=(b.getTime()-h.getTime())/864E5|0;N[a>>2]=b.getSeconds();N[a+4>>2]=b.getMinutes();N[a+8>>2]=b.getHours();N[a+12>>2]=b.getDate();N[a+16>>2]=b.getMonth();return b.getTime()/1E3|0},M:Ca,d:function(){F("")},m:function(a,b){if(0===a)a=Date.now();else if(1===a||4===a)a=Ga();else return N[Pa()>>2]=28,-1;N[b>>2]=a/1E3|0;N[b+4>>2]=a%1E3*1E6|0;return 0},p:function(a,b){return a-b},
s:function(){return 2147483648},l:Ga,E:function(a,b,e){I.copyWithin(a,b,b+e)},f:function(a){var b=I.length;a>>>=0;if(2147483648<a)return!1;for(var e=1;4>=e;e*=2){var f=b*(1+.2/e);f=Math.min(f,a+100663296);f=Math.max(a,f);0<f%65536&&(f+=65536-f%65536);a:{try{G.grow(Math.min(2147483648,f)-la.byteLength+65535>>>16);ma();var h=1;break a}catch(k){}h=void 0}if(h)return!0}return!1},C:function(a,b){var e=0;Ia().forEach(function(f,h){var k=b+e;h=N[a+4*h>>2]=k;for(k=0;k<f.length;++k)M[h++>>0]=f.charCodeAt(k);
M[h>>0]=0;e+=f.length+1});return 0},D:function(a,b){var e=Ia();N[a>>2]=e.length;var f=0;e.forEach(function(h){f+=h.length+1});N[b>>2]=f;return 0},e:function(){return 0},j:function(a,b,e,f){a=U.Oa(a);b=U.Ma(a,b,e);N[f>>2]=b;return 0},q:function(){},i:function(a,b,e,f){for(var h=0,k=0;k<e;k++){var l=N[b>>2],p=N[b+4>>2];b+=8;for(var v=0;v<p;v++){var u=I[l+v],q=Aa[a];0===u||10===u?((1===a?fa:D)(ja(q,0)),q.length=0):q.push(u)}h+=p}N[f>>2]=h;return 0},w:function(a){var b=Date.now();N[a>>2]=b/1E3|0;N[a+
4>>2]=b%1E3*1E3|0;return 0},o:La,c:function(a,b,e,f){return La(a,b,e,f)}};
(function(){function a(h){c.asm=h.exports;G=c.asm.N;ma();na=c.asm.ja;pa.unshift(c.asm.O);O--;c.monitorRunDependencies&&c.monitorRunDependencies(O);0==O&&(null!==P&&(clearInterval(P),P=null),Q&&(h=Q,Q=null,h()))}function b(h){a(h.instance)}function e(h){return va().then(function(k){return WebAssembly.instantiate(k,f)}).then(function(k){return k}).then(h,function(k){D("failed to asynchronously prepare wasm: "+k);F(k)})}var f={a:Qa};O++;c.monitorRunDependencies&&c.monitorRunDependencies(O);if(c.instantiateWasm)try{return c.instantiateWasm(f,
a)}catch(h){return D("Module.instantiateWasm callback failed with error: "+h),!1}(function(){return E||"function"!==typeof WebAssembly.instantiateStreaming||sa()||R.startsWith("file://")||"function"!==typeof fetch?e(b):fetch(R,{credentials:"same-origin"}).then(function(h){return WebAssembly.instantiateStreaming(h,f).then(b,function(k){D("wasm streaming compile failed: "+k);D("falling back to ArrayBuffer instantiation");return e(b)})})})().catch(g);return{}})();
c.___wasm_call_ctors=function(){return(c.___wasm_call_ctors=c.asm.O).apply(null,arguments)};c._OrtInit=function(){return(c._OrtInit=c.asm.P).apply(null,arguments)};c._OrtCreateSessionOptions=function(){return(c._OrtCreateSessionOptions=c.asm.Q).apply(null,arguments)};c._OrtAddSessionConfigEntry=function(){return(c._OrtAddSessionConfigEntry=c.asm.R).apply(null,arguments)};c._OrtReleaseSessionOptions=function(){return(c._OrtReleaseSessionOptions=c.asm.S).apply(null,arguments)};
c._OrtCreateSession=function(){return(c._OrtCreateSession=c.asm.T).apply(null,arguments)};c._OrtReleaseSession=function(){return(c._OrtReleaseSession=c.asm.U).apply(null,arguments)};c._OrtGetInputCount=function(){return(c._OrtGetInputCount=c.asm.V).apply(null,arguments)};c._OrtGetOutputCount=function(){return(c._OrtGetOutputCount=c.asm.W).apply(null,arguments)};c._OrtGetInputName=function(){return(c._OrtGetInputName=c.asm.X).apply(null,arguments)};
c._OrtGetOutputName=function(){return(c._OrtGetOutputName=c.asm.Y).apply(null,arguments)};c._OrtFree=function(){return(c._OrtFree=c.asm.Z).apply(null,arguments)};c._OrtCreateTensor=function(){return(c._OrtCreateTensor=c.asm._).apply(null,arguments)};c._OrtGetTensorData=function(){return(c._OrtGetTensorData=c.asm.$).apply(null,arguments)};c._OrtReleaseTensor=function(){return(c._OrtReleaseTensor=c.asm.aa).apply(null,arguments)};
c._OrtCreateRunOptions=function(){return(c._OrtCreateRunOptions=c.asm.ba).apply(null,arguments)};c._OrtAddRunConfigEntry=function(){return(c._OrtAddRunConfigEntry=c.asm.ca).apply(null,arguments)};c._OrtReleaseRunOptions=function(){return(c._OrtReleaseRunOptions=c.asm.da).apply(null,arguments)};c._OrtRun=function(){return(c._OrtRun=c.asm.ea).apply(null,arguments)};c._OrtEndProfiling=function(){return(c._OrtEndProfiling=c.asm.fa).apply(null,arguments)};
var Pa=c.___errno_location=function(){return(Pa=c.___errno_location=c.asm.ga).apply(null,arguments)},L=c._malloc=function(){return(L=c._malloc=c.asm.ha).apply(null,arguments)},Oa=c._free=function(){return(Oa=c._free=c.asm.ia).apply(null,arguments)},Na=c._memalign=function(){return(Na=c._memalign=c.asm.ka).apply(null,arguments)},Ra=c.stackSave=function(){return(Ra=c.stackSave=c.asm.la).apply(null,arguments)},Sa=c.stackRestore=function(){return(Sa=c.stackRestore=c.asm.ma).apply(null,arguments)},Ta=
c.stackAlloc=function(){return(Ta=c.stackAlloc=c.asm.na).apply(null,arguments)};c.UTF8ToString=H;c.stringToUTF8=function(a,b,e){return J(a,I,b,e)};c.lengthBytesUTF8=K;c.stackSave=Ra;c.stackRestore=Sa;c.stackAlloc=Ta;var Z;Q=function Ua(){Z||Va();Z||(Q=Ua)};
function Va(){function a(){if(!Z&&(Z=!0,c.calledRun=!0,!ha)){S(pa);ba(c);if(c.onRuntimeInitialized)c.onRuntimeInitialized();if(c.postRun)for("function"==typeof c.postRun&&(c.postRun=[c.postRun]);c.postRun.length;){var b=c.postRun.shift();qa.unshift(b)}S(qa)}}if(!(0<O)){if(c.preRun)for("function"==typeof c.preRun&&(c.preRun=[c.preRun]);c.preRun.length;)ra();S(oa);0<O||(c.setStatus?(c.setStatus("Running..."),setTimeout(function(){setTimeout(function(){c.setStatus("")},1);a()},1)):a())}}c.run=Va;
if(c.preInit)for("function"==typeof c.preInit&&(c.preInit=[c.preInit]);0<c.preInit.length;)c.preInit.pop()();Va();


  return ortWasm.ready
}
);
})();
if (typeof exports === 'object' && typeof module === 'object')
  module.exports = ortWasm;
else if (typeof define === 'function' && define['amd'])
  define([], function() { return ortWasm; });
else if (typeof exports === 'object')
  exports["ortWasm"] = ortWasm;
